import React from "react"
import { Box, Button, Typography } from "@lib/ui/core"
import Container from "@layouts/Container"
import Layout from "@layouts/MainLayout"

const ContactPage = () => {
  return (
    <Layout bgcolor="#14a1e9" homepage>
      <Box display="flex" width="100%">
        <Container>
          <Box bgcolor="#ffffff" my={2} p={2} borderRadius="32px">
            <Box fontSize="32px" fontWeight="700" align="center">
              聯絡我們
            </Box>
            <Box my={5}>
              <Box fontSize="24px" mb={1}>
                可透過Whatsapp： +852 9435 6571
              </Box>
              <a
                href="https://api.whatsapp.com/send?phone=85294356571"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button>
                  <Typography color="textSecondary">
                    點擊開啟Whatsapp與我們聯繫
                  </Typography>
                </Button>
              </a>
            </Box>
            <Box my={5}>
              <Box fontSize="24px" my={2}>
                或親臨我們顧客中心
              </Box>
              <Box mb={2}>
                <Box fontSize="20px" mb={1}>
                  九龍油麻地彌敦道彌敦行20樓D室
                </Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.091315920444!2d114.16874331463907!3d22.312385985317317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400c11cefb4a7%3A0x14f2450ee4077130!2z5b2M5pWm6KGM!5e0!3m2!1sen!2shk!4v1607966086227!5m2!1sen!2shk"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                />
              </Box>
              <Box>
                <Box fontSize="20px" mb={1}>
                  天后電氣道108號 冠貿商業大廈1201室
                </Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.2189831819655!2d114.18972865753975!3d22.285094042065076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040100268f9269%3A0xdb5dfe1ee903e962!2z6YqF6ZG854Gj6Zu75rCj6YGTMTA46Jmf5Yag6LK_5ZWG5qWt5aSn5buI!5e0!3m2!1szh-TW!2shk!4v1608046605765!5m2!1szh-TW!2shk"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default ContactPage
